require('dropify');

$(document).ready(function(){
    $('.dropify').dropify({
        messages: {
            'default': 'Segure e solte o arquivo aqui ou clique',
            'replace': 'Segure e solte o arquivo aqui ou clique para substituir',
            'remove':  'Remove',
            'error':   'Ooops, alguma coisa errada aconteceu.'
        },
        error: {
            'fileSize': 'O arquivo é muito grande. ({{ value }} é o tamanho máximo).',
            'fileExtension': 'O formato do arquivo não é permitido. Somente ({{ value }} é aceito).'
        }
    });
});
