
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./plugins/jquery-mask-plugin');
require('./plugins/jquery-validation');
require('./plugins/dropify');
require('./plugins/summernote');
require('./plugins/select2');
require('./plugins/bs-custom-file-input');
require('./plugins/add-input-area');
require('bootstrap4-toggle/js/bootstrap4-toggle.min');

require('./helpers/form-load');
require('./helpers/slug-js');

require('./custom/logoff-form');


