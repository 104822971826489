$.mask = require('jquery-mask-plugin');

const globalMask = () => {
    $('.mask-date').mask('00/00/0000');
    $('.mask-date_expiry').mask('00/00');
    $('.mask-cvc').mask('0000');
    $('.mask-time_complete').mask('00:00:00');
    $('.mask-time').mask('00:00');
    $('.mask-date_time').mask('00/00/0000 00:00:00');
    $('.mask-cep').mask('00000-000');
    $('.mask-phone').mask('0000-0000');
    $('.mask-phone_with_ddd').mask('(00) 0000-0000');
    $('.mask-cellphone_with_ddd').mask('(00)00000-0000');
    $('.mask-phone_us').mask('(000) 000-0000');
    $('.mask-mixed').mask('AAA 000-S0S');
    $('.mask-cpf').mask('000.000.000-00', {reverse: true});
    $('.mask-money').mask("#.##0,00", {
      	reverse:true,
        onKeyPress: function(v, event, currentField, options) {
          // remove previously added stuff at start of string
          v = v.replace(/^0*,?0*/, '');

          // add stuff as needed
          if (v.length == 0 ) {
              v = "0,00";
          }
          else if (v.length == 1 ) {
              v = "0,0" + v;
          }
          else if (v.length == 2 ) {
              v = "0," + v;
          }
          $(currentField).val(v);

        }
      });
    $('.mask-uppercase').mask('S', {
        'translation': {
            S: {pattern: /[A-Za-z0-9]/, recursive: true},
        },
        onKeyPress: function (value, event) {
            event.currentTarget.value = value.toUpperCase();
        }
    });
}

$(document).ready(function(){
    globalMask();
});

export default globalMask;
