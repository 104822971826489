import globalMask from "./jquery-mask-plugin";

require('jquery.add-input-area');
require('./dropify');

$("#list-append-form-plugin").addInputArea({
    after_add: function () {
        globalMask();
    }
});

$("#list-append-images-plugin").addInputArea({
    after_add: function () {
        let newImage = $('.list-append-images-plugin_var').last().find('.dropify');
        newImage.data('default-file', null);
        newImage.dropify();
    }
});
